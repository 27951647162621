import type { ButtonHTMLAttributes } from "react"
import React from "react"
import { useColors, type StyleProps } from "@mallardbay/lib-react-components"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"
import UnstyledButton from "~components/shared/todo-lib-react-components/unstyled-button"

// TODO can this be combined with `Card`?

interface Props
    extends PropsWithChildrenAndStyle,
        Omit<ButtonHTMLAttributes<HTMLButtonElement>, "style"> {}

export default function ClickableCard({ style, ...props }: Props) {
    const styles = useStyles()

    const combinedStyles = {
        ...styles.card,
        ...(style || {}),
    }

    return <UnstyledButton {...props} style={combinedStyles} />
}

function useStyles() {
    const colors = useColors()

    const card: StyleProps = {
        backgroundColor: colors.bgColor,
        border: `1px solid ${colors.borderColor}`,
        borderRadius: "15px",
        boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 12px",
        transition: "all 0.2s ease-in-out",
        overflow: "hidden",
    }

    return {
        card,
    }
}
