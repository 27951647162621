import React from "react"
import { Divider as DividerOg } from "@chakra-ui/react"
import type { StyleProps } from "@mallardbay/lib-react-components"

import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"

interface Props {
    readonly style?: StyleProps
}

export default function Divider({ style }: Props) {
    const styles = useStyles()

    return <DividerOg sx={{ ...styles.root, ...style }} />
}

function useStyles() {
    return componentStyles({
        root: {
            marginY: 2,
        },
    })
}
