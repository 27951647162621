import React from "react"

// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"

interface Props {
    readonly label: string
}

export default function SearchDropdownGroupLabel({ label }: Props) {
    const styles = useStyles()

    return <div className={styles.root}>{label}</div>
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        root: css({
            fontSize: "14px",
            fontWeight: 700,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: theme.sizing.scale900,
            marginBottom: theme.sizing.scale400,
            paddingRight: theme.sizing.scale200,
            paddingLeft: theme.sizing.scale200,
            textTransform: "uppercase",
            ":first-child": {
                marginTop: theme.sizing.scale200,
            },
            [theme.mediaQuery.small]: {
                paddingRight: theme.sizing.scale800,
                paddingLeft: theme.sizing.scale800,
            },
        }),
    }
}
