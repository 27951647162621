import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "@reach/router"

import ResponsiveBlock from "~components/shared/responsive-block"
import HiddenOnMobileContainer from "~components/shared/wrappers/hidden-on-mobile-container"
import HiddenOnDesktopContainer from "~components/shared/wrappers/hidden-on-desktop-container"
import TextLogo from "~components/shared/logo/text-logo"
import HeaderLoginButton from "~components/shared/header/header-login-button"
import HeaderProfileButton from "~components/shared/header/header-profile-button"
import OutfitterCallToActionButton from "~components/shared/header/outfitter-call-to-action-button"
import HeaderSearchBar from "~components/shared/header/header-search-bar"
import { useResponsive } from "~config/responsive-provider"
import { useAuth } from "~utils/auth-hooks"
import { PAGE_HEADER_HEIGHT } from "~config/constants"
import {
    SUGGESTIONS_POPOVER_ID,
    CALENDAR_POPOVER_ID,
} from "~components/shared/search-input/desktop-search-input"
import { useTabletMediaQueries } from "~utils/use-media-queries"
import CourierInbox from "~components/shared/courier-inbox/courier-inbox"
import {
    useTheme as useThemeNew,
    componentStyles,
} from "~components/shared/todo-lib-react-components/hooks/use-theme"
import Box from "~components/shared/todo-lib-react-components/box"

export default function Header() {
    const { isLoading, isAuthenticated } = useAuth()
    const { collapsed, setCollapsed, headerRef } = useHeaderCollapse()
    const styles = useStyles({ isCollapsed: collapsed })

    const shouldRender = useShouldRenderHeader()

    if (!shouldRender) return null

    return (
        <>
            <Box style={styles.overlay} />
            <ResponsiveBlock style={styles.root}>
                {/* MOBILE */}
                <HiddenOnDesktopContainer>
                    <Box style={styles.headerContent}>
                        <HeaderSearchBar clickHandler={() => {}} />
                    </Box>
                </HiddenOnDesktopContainer>

                {/* DESKTOP */}
                <HiddenOnMobileContainer>
                    <Box ref={headerRef} style={styles.headerContent}>
                        <TextLogo />
                        <HeaderSearchBar
                            isCollapsed={collapsed}
                            clickHandler={() => setCollapsed(false)}
                        />
                        <Box style={styles.headerRight}>
                            <div>
                                {collapsed && <OutfitterCallToActionButton />}
                            </div>
                            <CourierInbox />
                            {(isLoading || isAuthenticated) && (
                                <HeaderProfileButton />
                            )}
                            {!isLoading && !isAuthenticated && (
                                <HeaderLoginButton />
                            )}
                        </Box>
                    </Box>
                </HiddenOnMobileContainer>
            </ResponsiveBlock>
        </>
    )
}

function useShouldRenderHeader() {
    const { pathname } = useLocation()
    const { isTabletOrLarger } = useTabletMediaQueries()

    return isTabletOrLarger || pathname === "/" || pathname.includes("/search")
}

function useHeaderCollapse() {
    const headerRef = useRef<HTMLDivElement | null>(null)
    const [collapsed, setCollapsed] = useState(true)
    const { windowScroll } = useResponsive()
    const { pathname } = useLocation()

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const clickedNode = event.target as Node
            const searchInputPopovers = document.querySelectorAll(
                `[id="${SUGGESTIONS_POPOVER_ID}"], [id="${CALENDAR_POPOVER_ID}"]`
            )

            const isClickedInsideHeader =
                headerRef.current?.contains(clickedNode)

            searchInputPopovers.forEach((popoverElement) => {
                if (
                    popoverElement.contains(clickedNode) ||
                    isClickedInsideHeader
                ) {
                    return
                } // Do nothing if clicked inside search input popover or header

                // Collapse search input if clicked outside search input popover or header
                setCollapsed(true)
            })
        }
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [headerRef, windowScroll.y])

    useEffect(() => {
        if (windowScroll.y > 30) {
            setCollapsed(true)
        }
    }, [windowScroll.y])

    // Collapse search input when navigating to new page
    useEffect(() => {
        setCollapsed(true)
    }, [pathname])

    return { collapsed, setCollapsed, headerRef }
}

function useStyles({ isCollapsed }: { isCollapsed: boolean }) {
    const { pathname } = useLocation()
    const { windowScroll } = useResponsive()
    const { colors, shadows } = useThemeNew()
    const scrolled = windowScroll.y > 30

    const isHomePage = pathname === "/"
    const shouldUseBgColor = scrolled || !isCollapsed || !isHomePage
    const mainBackgroundColor = shouldUseBgColor
        ? colors.bgColor
        : "transparent"
    const mobileBoxShadow = scrolled ? shadows.cardShadow : "none"
    const backdropFilter = scrolled ? "blur(24px)" : "blur(0px)"

    return componentStyles({
        root: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            boxSizing: "border-box",
            boxShadow: { base: mobileBoxShadow, md: "none" },
            transition: "all 0.2s ease-in-out",
            backgroundColor: { base: colors.bgColor, md: mainBackgroundColor },
            zIndex: 200,
            backdropFilter: { md: backdropFilter },
            WebkitBackdropFilter: { md: backdropFilter },
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            pointerEvents: "none",
            backgroundColor: isCollapsed ? "transparent" : colors.black,
            visibility: isCollapsed ? "hidden" : "visible",
            transition: "all 0.2s ease-in-out",
            opacity: 0.2,
        },
        headerContent: {
            width: "100%",
            display: { md: "flex" },
            flexDirection: { md: "row" },
            alignItems: { md: "center" },
            justifyContent: { md: "space-between" },
            height: { base: "auto", md: PAGE_HEADER_HEIGHT },
        },
        headerRight: {
            display: "flex",
            minWidth: "200px",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
        },
    })
}
