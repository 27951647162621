import React from "react"
import { isSameDay } from "date-fns"

import { useDesktopMediaQueries } from "~utils/use-media-queries"
import { getAvailableListingDates } from "~utils/helpers"
import type { LegacyAvailabilityFieldsFragment } from "~graphql/generated/graphql"
import Calendar from "~components/shared/calendar"
// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"
import { CalendarOrientation } from "~config/constants"

export default function AvailabilityCalendar({
    minDate,
    includeDates,
    value,
    onChange,
    availabilityForCalendar = [],
    showLabel = false,
}: AvailabilityCalendarProps) {
    const { isDesktopOnly } = useDesktopMediaQueries()

    const maxSlots = Math.max(
        ...availabilityForCalendar.map(
            (availability) => availability.available_slots
        )
    )

    const availableDates = getAvailableListingDates({
        includeDates: includeDates || [],
        minDate: minDate || new Date(Date.now()),
    })

    return (
        <Calendar
            range
            minDate={minDate}
            includeDates={availableDates.length ? availableDates : undefined}
            orientation={CalendarOrientation.Horizontal}
            monthsShown={isDesktopOnly ? 2 : 1}
            value={value}
            onChange={onChange}
            autoFocusCalendar
            overrides={{
                CalendarHeader: { style: { backgroundColor: "transparent" } },
                MonthHeader: { style: { backgroundColor: "transparent" } },
                Day: {
                    style: {
                        fontWeight: 600,
                        height: showLabel ? "64px" : "auto",
                    },
                },
                DayLabel: { style: { fontSize: "11px" } },
            }}
            renderDateLabel={
                showLabel
                    ? (date) => {
                          const matchedDate = availabilityForCalendar.filter(
                              (availability) =>
                                  isSameDay(new Date(availability.date), date)
                          )[0]

                          return matchedDate ? (
                              <div
                                  style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                  }}
                              >
                                  <AvailabilityIndicator
                                      slots={matchedDate.available_slots}
                                      maxSlots={maxSlots}
                                  />
                              </div>
                          ) : (
                              `-`
                          )
                      }
                    : undefined
            }
        />
    )
}

function AvailabilityIndicator({
    slots,
    maxSlots,
}: AvailabilityIndicatorProps) {
    const styles = useStyles({ slots, maxSlots })

    return <div className={styles.statusDot} />
}

interface AvailabilityCalendarProps {
    readonly minDate?: Date
    readonly includeDates?: Date[]
    readonly value?: Date[]
    // TODO create an intermidiary onChange so that the params passed to it
    // are simpler than the types user by baseui. This will simplify usage
    // of this component
    readonly onChange?: (newDate: {
        date: (Date | undefined | null)[] | Date | undefined | null
    }) => void
    readonly availabilityForCalendar?: LegacyAvailabilityFieldsFragment[]
    readonly showLabel?: boolean
}

interface AvailabilityIndicatorProps {
    readonly slots: number
    readonly maxSlots: number
}

function useStyles({ slots, maxSlots }: AvailabilityIndicatorProps) {
    const { css } = useTheme()

    const backgroundColor = useStatusDotColor({ slots, maxSlots })

    return {
        statusDot: css({
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            backgroundColor,
        }),
    }
}

function useStatusDotColor({ slots, maxSlots }: AvailabilityIndicatorProps) {
    const { theme } = useTheme()

    if (!slots) return theme.colors.negative400

    return slots / maxSlots < 0.2
        ? theme.colors.warning400
        : theme.colors.positive300
}
