import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Search } from "baseui/icon"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { ButtonOverrides, ButtonProps } from "baseui/button"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Button, SHAPE } from "baseui/button"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useStyletron } from "baseui"

// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"

export default function SearchButton(props: Readonly<ButtonProps>) {
    const styles = useStyles()
    const overrides = useButtonOverrides()

    return (
        <div className={styles.root}>
            <Button
                type="submit"
                shape={SHAPE.pill}
                overrides={overrides}
                startEnhancer={<Search size={24} />}
                {...props}
            >
                Search
            </Button>
        </div>
    )
}

function useButtonOverrides(): ButtonOverrides {
    const { theme } = useTheme()

    return {
        BaseButton: {
            style: ({ $theme }) => ({
                paddingTop: "12px",
                paddingRight: "14px",
                paddingBottom: "12px",
                paddingLeft: "14px",
                backgroundImage:
                    "linear-gradient(90deg, rgba(246,94,56,1) 0%, rgba(246,138,57,1) 50%, rgba(246,94,56,1) 100%)",
                backgroundSize: "200% auto",
                backgroundPosition: "right center",
                boxShadow: theme.boxShadows.small,
                fontWeight: 600,
                ":hover": {
                    backgroundPosition: "left center",
                },
                ":disabled": {
                    opacity: 0.7,
                    color: $theme.colors.backgroundPrimary,
                },
            }),
        },
    }
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            display: "none",
            alignItems: "center",
            [theme.mediaQuery.small]: {
                display: "flex",
            },
        }),
    }
}
