import {
    SearchSuggestionsType,
    useGetSearchSuggestionsQuery,
} from "~graphql/generated/graphql"

export default function useSearchSuggestions({
    type = SearchSuggestionsType.Listings,
}: {
    type?: SearchSuggestionsType
} = {}) {
    const {
        data,
        loading: isLoading,
        error,
    } = useGetSearchSuggestionsQuery({ variables: { type } })

    const searchSuggestions = data?.getSearchSuggestions ?? []

    return { searchSuggestions, isLoading, error }
}
