import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useStyletron } from "baseui"

export default function DotSeparator() {
    const styles = useStyles()

    return <span className={styles.root}>·</span>
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            marginTop: "0",
            marginRight: theme.sizing.scale200,
            marginBottom: "0",
            marginLeft: theme.sizing.scale200,
        }),
    }
}
