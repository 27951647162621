import React from "react"
import { Text } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

export default function HeadingXSmall({
    children,
    style,
}: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <Text sx={{ ...styles.xsmallHeading, ...style }}>{children}</Text>
}

function useStyles() {
    const xsmallHeading = {
        fontWeight: "bold",
        fontSize: "17px",
    }

    return { xsmallHeading }
}
