import type { MouseEventHandler } from "react"
import React from "react"
import { Link } from "@reach/router"

import Skeleton from "~components/shared/todo-lib-react-components/skeleton"
import HeadingXSmall from "~components/shared/todo-lib-react-components/heading-x-small"
// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"
import AspectImageWithPill from "~components/shared/todo-lib-react-components/aspect-image-with-pill"
import ConditionalRenderContainer from "~components/shared/conditional-render-container"
import ClickableCard from "~components/shared/todo-lib-react-components/clickable-card"

import ImageCardPill from "./image-card-pill"

export interface Props {
    readonly onClick?: MouseEventHandler<HTMLButtonElement>
    readonly headerImageSrc?: string
    readonly headerImageAlt?: string
    readonly headerImageRatio?: number
    readonly aboveTitleText?: string
    readonly title: string
    readonly subtitle?: string | null
    readonly children?: React.ReactNode
    readonly isLoading?: boolean
    readonly isDisabled?: boolean
    readonly pillLabel?: string | null
    readonly topRightCornerContent?: React.ReactNode
    readonly linkTo?: string | null
    readonly testId?: string
}

export default function ImageCard({
    onClick,
    headerImageSrc,
    headerImageAlt,
    headerImageRatio = 16 / 9,
    aboveTitleText,
    title,
    subtitle,
    children,
    isLoading,
    isDisabled,
    pillLabel,
    topRightCornerContent,
    linkTo,
    testId,
}: Props) {
    const styles = useStyles({ onClick })
    const isOnCLickDisabled =
        !onClick || Boolean(isDisabled) || Boolean(isLoading)

    const pill = <ImageCardPill label={pillLabel} />

    return (
        <ClickableCard
            style={styles.card}
            onClick={isOnCLickDisabled ? () => {} : onClick}
            data-testid={testId}
        >
            <div className={styles.container}>
                <ConditionalRenderContainer
                    shouldRender={!!topRightCornerContent}
                    className={styles.topRightCornerComponent}
                >
                    {topRightCornerContent}
                </ConditionalRenderContainer>

                <AspectImageWithPill
                    imageSrc={headerImageSrc}
                    pill={pill}
                    isLoading={isLoading}
                    imageAlt={headerImageAlt}
                    ratio={headerImageRatio}
                    linkTo={linkTo}
                />
                <div className={styles.content}>
                    {aboveTitleText && (
                        <div className={styles.aboveTitleText}>
                            {aboveTitleText}
                        </div>
                    )}
                    {isLoading ? (
                        <Skeleton rows={1} />
                    ) : (
                        <ImageCardTitle title={title} linkTo={linkTo} />
                    )}

                    {subtitle && (
                        <p className={styles.subtitle}>
                            {isLoading ? <Skeleton rows={1} /> : subtitle}
                        </p>
                    )}
                    <div>{children}</div>
                </div>
            </div>
        </ClickableCard>
    )
}

function ImageCardTitle({ linkTo, title }: Pick<Props, "linkTo" | "title">) {
    const styles = useStyles({ onClick: undefined })

    return (
        <HeadingXSmall style={styles.title}>
            {linkTo ? <Link to={linkTo}>{title}</Link> : title}
        </HeadingXSmall>
    )
}

function useStyles({
    onClick,
}: {
    onClick?: MouseEventHandler<HTMLButtonElement>
}) {
    const { css, theme } = useTheme()

    const containerCursor = onClick ? "pointer" : "default"
    const titleLineCount = 2
    const titleLineHeightEm = 1.3
    const titleHeight = `${titleLineHeightEm * titleLineCount}em`
    const titleLineHeight = `${titleLineHeightEm}em`

    return {
        imagePill: css({
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
        }),
        card: {
            cursor: containerCursor,
            padding: 0,
            position: "relative",
            width: "100%",
            height: "100%",
            whiteSpace: "normal",
            ":hover": {
                boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px ${theme.sizing.scale500}`,
            },
        },
        container: css({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
        }),
        content: css({
            height: "100%",
            paddingLeft: theme.sizing.scale800,
            paddingRight: theme.sizing.scale800,
            paddingBottom: theme.sizing.scale600,
            paddingTop: theme.sizing.scale600,
            borderTop: `1px solid ${theme.colors.borderOpaque}`,
            backgroundColor: theme.colors.backgroundPrimary,
        }),
        headerImage: css({
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            height: "100%",
        }),
        aboveTitleText: css({
            fontSize: "14px",
            fontWeight: 300,
            color: theme.colors.contentTertiary,
            marginBottom: "0.2em",
        }),
        subtitle: css({
            marginTop: theme.sizing.scale200,
            fontSize: "16px",
            color: theme.colors.contentTertiary,
            userSelect: "none",
            width: "100%",
        }),
        title: {
            textDecoration: "none",
            // Show a max of two lines, then ellipsis
            // https://stackoverflow.com/a/32585024
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkitBoxOrient": "vertical",
            "-webkitLineClamp": 2,
            // Fallback
            lineHeight: titleLineHeight,
            height: titleHeight /* height is 2x line-height, so two lines will display */,
            // This number was stratigically set to allow long names to be displayed. Changing it should be done with caution.
            fontSize: "14px !important",
        },
        topRightCornerComponent: css({
            position: "absolute",
            top: "13px",
            right: "13px",
            zIndex: 200,
        }),
        bottomSection: css({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        }),
    }
}
