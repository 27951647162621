import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Search } from "baseui/icon"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useStyletron } from "baseui"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { InputOverrides } from "baseui/input"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Input } from "baseui/input"

import { SEARCH_FONT_SIZE } from "~config/constants"
import { TEST_IDS } from "~config/test-ids"
import SearchDropdown from "~components/shared/search-input/search-dropdown"

interface Props {
    readonly input: string
    readonly setInput: (input: string) => void
    readonly setIsSearching: (isSearching: boolean) => void
    readonly setActiveStep: (step: number) => void
}

export default function SearchView({
    input,
    setInput,
    setIsSearching,
    setActiveStep,
}: Props) {
    const styles = useStyles()

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        event.stopPropagation()
        setIsSearching(false)
        setActiveStep(1)
    }

    return (
        <form
            onSubmit={handleSubmit}
            className={styles.suggestions}
            data-testid={TEST_IDS.MOBILE_SEARCH_VIEW}
        >
            <Input
                value={input}
                onChange={(event) => setInput(event.currentTarget.value)}
                placeholder="Search locations, species, listings..."
                overrides={getInputOverrides()}
                autoFocus
            />
            <div className={styles.suggestionList}>
                <SearchDropdown
                    keyword={input}
                    onKeywordChange={(newKeyword) => {
                        setIsSearching(false)
                        setInput(newKeyword)
                    }}
                />
            </div>
        </form>
    )
}

function getInputOverrides(): InputOverrides {
    return {
        Root: {
            style: ({ $theme }) => {
                return {
                    borderTopLeftRadius: $theme.sizing.scale400,
                    borderTopRightRadius: $theme.sizing.scale400,
                    borderBottomLeftRadius: $theme.sizing.scale400,
                    borderBottomRightRadius: $theme.sizing.scale400,
                    overflow: "hidden",
                    borderTopWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftWidth: "1px",
                    borderColor: $theme.colors.contentInverseTertiary,
                }
            },
        },
        InputContainer: {
            style: ({ $theme }) => {
                return {
                    paddingTop: $theme.sizing.scale200,
                    paddingBottom: $theme.sizing.scale200,
                    paddingLeft: $theme.sizing.scale400,
                    paddingRight: $theme.sizing.scale400,
                    backgroundColor: $theme.colors.backgroundPrimary,
                }
            },
        },
        Input: {
            props: {
                "data-testid": TEST_IDS.MOBILE_SEARCH_TEXT_INPUT,
            },
            style: ({ $theme }) => {
                return {
                    color: $theme.colors.contentPrimary,
                    fontSize: SEARCH_FONT_SIZE,
                    fontWeight: "600",
                }
            },
        },
        Before: function Before() {
            return (
                <Search
                    size={24}
                    style={{
                        marginLeft: "6px",
                        alignSelf: "center",
                    }}
                />
            )
        },
    }
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        suggestions: css({
            flex: 1,
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            backgroundColor: theme.colors.backgroundPrimary,
            marginTop: theme.sizing.scale400,
            paddingTop: theme.sizing.scale600,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale600,
            borderTopLeftRadius: theme.sizing.scale800,
            borderTopRightRadius: theme.sizing.scale800,
            boxShadow: theme.lighting.shadow400,
        }),
        suggestionList: css({
            flex: 1,
            marginTop: theme.sizing.scale400,
            overflowY: "scroll",
        }),
    }
}
