import type { PropsWithChildren } from "react"
import React, { Children } from "react"

import CollectionButton from "~components/shared/page-sections/entity-card-collection/collection-button"
import ResponsiveSlider from "~components/shared/responsive-slider/responsive-slider"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import Box from "~components/shared/todo-lib-react-components/box"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly buttonLabel?: string | null
    readonly buttonUrl?: string | null
    readonly shouldCenterMobile?: boolean
    readonly onSwipe?: () => void
    readonly hideCount?: boolean
}

export default function GenericCollectionSlider({
    label,
    buttonLabel,
    buttonUrl,
    children,
    shouldCenterMobile,
    onSwipe,
    hideCount,
}: Props) {
    const styles = useStyles()
    const childrenAsArray = Children.toArray(children)

    return (
        <>
            <ResponsiveSlider
                title={label}
                items={childrenAsArray}
                shouldCenterMobile={shouldCenterMobile}
                onSwipe={onSwipe}
                hideCount={hideCount}
            />
            <Box style={styles.buttonContainer}>
                <CollectionButton label={buttonLabel} href={buttonUrl} />
            </Box>
        </>
    )
}

function useStyles() {
    return componentStyles({
        buttonContainer: {
            textAlign: "center",
            marginTop: 6,
        },
    })
}
