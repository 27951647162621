import React from "react"
import { Link } from "gatsby-link"
import { RiUser3Line } from "react-icons/ri"

import { useAuth } from "~utils/auth-hooks"
import COPY from "~config/copy-constants"
import Avatar from "~components/shared/tab-bar/Avatar"
import { NAVIGATION_URLS, useIsAccountRoute } from "~utils/navigation-helpers"
// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"

export default function ProfileTab() {
    const styles = useStyles()
    const isAccountRoute = useIsAccountRoute()
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth()

    const labelStyle = isAccountRoute ? styles.labelActive : styles.label
    const isLoadingOrAuthenticated = isLoading || isAuthenticated
    const isNotLoadingAndNotAuthenticated = !isAccountRoute && !isAuthenticated

    return (
        <div>
            {isLoadingOrAuthenticated && (
                <Link
                    to={NAVIGATION_URLS.INTERNAL.ACCOUNT}
                    className={`${styles.tab} ${
                        isAccountRoute && styles.colored
                    }`}
                >
                    <Avatar />
                    <p className={labelStyle}>{COPY.ACCOUNT}</p>
                </Link>
            )}
            {isNotLoadingAndNotAuthenticated && (
                <div className={styles.tab} onClick={loginWithRedirect}>
                    <RiUser3Line className={styles.icon} />
                    {COPY.LOG_IN}
                </div>
            )}
        </div>
    )
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        tab: css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
            width: "70px",
            cursor: "pointer",
            paddingTop: "2px",
            color: theme.colors.contentTertiary,
            transition: `all ${theme.animation.timing100}`,
            opacity: 0.85,
        }),
        colored: css({
            opacity: "1 !important",
            color: "#f65e38 !important",
        }),
        icon: css({
            height: "25px",
            width: "25px",
            marginBottom: "6px",
        }),
        label: css({
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            color: theme.colors.contentTertiary,
        }),
        labelActive: css({
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            fontWeight: 600,
            color: theme.colors.contentPrimary,
        }),
    }
}
