import React, { type PropsWithChildren } from "react"
import type { IconType } from "react-icons"

import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import { Text } from "~components/shared/todo-lib-react-components/text"
import HorizontalStackSpaceBetween from "~components/shared/todo-lib-react-components/horizontal-stack-space-between"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly Icon?: IconType
    readonly isBold?: boolean
}
export default function MenuButtonLabelAndIcon({
    label,
    Icon,
    isBold,
    children,
}: Props) {
    const styles = useStyles({ isBold })

    return (
        <HorizontalStackSpaceBetween style={styles.root}>
            {children}
            {label && <Text style={styles.label}>{label}</Text>}
            {Icon && <Icon style={styles.icon} />}
        </HorizontalStackSpaceBetween>
    )
}

function useStyles({ isBold }: { isBold?: boolean }) {
    return componentStyles({
        root: {
            paddingY: 2,
            paddingX: 5,
        },
        label: {
            fontWeight: isBold ? 600 : 300,
        },
        icon: {
            fontSize: "18px",
        },
    })
}
