export const INPUTS = {
    COLLECTION_TYPE: "type",
    LABEL: "label",
    BUTTON_LABEL: "buttonLabel",
    BUTTON_URL: "buttonUrl",
    QUERY_TYPE: "queryType",
    ID_LIST: "idList",
    SEARCH_STRING: "searchString",
    STATE: "state",
    ID_MAPS: "idMaps",
    ID: "id",
}

export const INPUT_TYPES = {
    STRING: "string",
    LIST: "list",
}

export const DEFAULT_VALUES = {
    LABEL: "Collection",
    BUTTON_LABEL: "See more",
    STATE: "Texas",
    // eslint-disable-next-line no-secrets/no-secrets
    SEARCH_STRING: "query=hunt&species=afnfYLAPqbXI&type=HUNTING",
}

export const BUILDER_CUSTOM_COMPONENTS = {
    PRIMARY_BUTTON: "Primary Button",
    OUTFITTERS_COLLECTION: "Outfitters Collection",
    LISTINGS_COLLECTION: "Listings Collection",
}
