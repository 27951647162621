import { useSearchListingsQuery } from "~graphql/generated/graphql"

export default function useSearchListings({
    keyword,
    from = 0,
}: {
    keyword: string
    from?: number
}) {
    const {
        data,
        loading: isLoading,
        error,
    } = useSearchListingsQuery({ variables: { data: { text: keyword }, from } })

    const listings = data?.listings.results ?? []
    const total = data?.listings.total ?? 0

    return { listings, total, isLoading, error }
}
