import { navigate } from "gatsby-link"

export function handleSearch(value: string, dates: Date[]) {
    void navigate(convertParamsToQueryString(value, dates))
}

export function convertParamsToQueryString(query: string, dates: Date[] = []) {
    let dateSearchParams = ""

    if (query === "") return ""

    if (dates[0] && dates[1]) {
        dateSearchParams =
            dates.length > 1
                ? `&startDate=${dates[0].getTime()}&endDate=${dates[1].getTime()}`
                : ""
    }

    return `/search?query=${query}${dateSearchParams}`
}
