import React from "react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import { Heading } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

export default function HeadingMedium({
    style,
    children,
}: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return (
        <Heading sx={{ ...styles.root, ...style }} size="lg" as="h2">
            {children}
        </Heading>
    )
}

function useStyles() {
    const root: StyleProps = {
        fontSize: "18px",
    }

    return { root }
}
