import React, { type PropsWithChildren } from "react"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"

export function ReactQueryProvider({ children }: Readonly<PropsWithChildren>) {
    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}
