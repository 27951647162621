import type { IconButtonProps } from "@chakra-ui/react"
import { IconButton as IconButtonOg } from "@chakra-ui/react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import React from "react"

import { useColors } from "../hooks/use-colors"

interface Props {
    readonly color?: string
    readonly ariaLabel: string
    readonly icon: React.ReactElement
    readonly onClick: () => void
    readonly style?: StyleProps
    readonly isDisabled?: boolean
    readonly testId?: string
    readonly variant?: IconButtonProps["variant"]
}

export function MediumIconButton({
    color,
    ariaLabel,
    icon,
    onClick,
    style,
    isDisabled,
    testId,
    variant,
}: Props) {
    const colors = useColors()

    return (
        <IconButtonOg
            sx={{
                ...style,
                backgroundColor: colors.bgColor,
                ":hover": {
                    backgroundColor: colors.bgColor,
                    transform: "scale(1.05)",
                },
            }}
            rounded="full"
            size="md"
            colorScheme={color}
            aria-label={ariaLabel}
            icon={icon}
            onClick={onClick}
            isDisabled={isDisabled}
            data-testid={testId}
            variant={variant}
        />
    )
}
