import React from "react"
import { FaDotCircle } from "react-icons/fa"
import type { IconType } from "react-icons"

// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"
import { useThemeContext } from "~config/theme-provider"
import type { SearchSuggestionItem } from "~utils/use-search-bar-suggestions"
import ConditionalRenderContainer from "~components/shared/conditional-render-container"

interface Props {
    readonly suggestion: SearchSuggestionItem
    readonly onClick: (suggestion: SearchSuggestionItem) => void
    readonly FallbackIcon?: IconType
}

export default function SearchDropdownSuggestion({
    suggestion,
    onClick,
    FallbackIcon = FaDotCircle,
}: Props) {
    const styles = useStyles()

    const hasIcon = Boolean(suggestion.icon_url)
    const hasAvatar = Boolean(suggestion.avatar)
    const shouldRenderFallbackIcon = !hasIcon && !hasAvatar

    return (
        <button
            className={styles.root}
            type="button"
            onClick={() => onClick(suggestion)}
        >
            <ConditionalRenderContainer
                className={styles.icon}
                shouldRender={Boolean(suggestion.icon_url)}
            >
                <img
                    className={styles.iconImage}
                    src={suggestion.icon_url ?? ""}
                    alt={suggestion.label}
                />
            </ConditionalRenderContainer>
            <ConditionalRenderContainer
                shouldRender={Boolean(suggestion.avatar)}
            >
                <img
                    alt={suggestion.label}
                    src={suggestion.avatar}
                    className={styles.avatar}
                />
            </ConditionalRenderContainer>
            <ConditionalRenderContainer shouldRender={shouldRenderFallbackIcon}>
                <FallbackIcon />
            </ConditionalRenderContainer>
            <div className={styles.text}>{suggestion.label}</div>
        </button>
    )
}

function useStyles() {
    const { css, theme } = useTheme()
    const { isDarkMode } = useThemeContext()

    return {
        root: css({
            color: theme.colors.contentPrimary,
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "40px 1fr",
            gridColumnGap: theme.sizing.scale600,
            width: "100%",
            paddingTop: theme.sizing.scale200,
            paddingRight: theme.sizing.scale200,
            paddingBottom: theme.sizing.scale200,
            paddingLeft: theme.sizing.scale200,
            border: "none",
            outline: "none",
            background: "none",
            [theme.mediaQuery.small]: {
                paddingRight: theme.sizing.scale800,
                paddingLeft: theme.sizing.scale800,
                ":hover": {
                    cursor: "pointer",
                    backgroundColor: theme.colors.backgroundSecondary,
                },
            },
        }),
        icon: css({
            display: "grid",
            placeItems: "center",
            width: "40px",
            height: "40px",
            overflow: "hidden",
            transform: "translateZ(0)",
            backgroundColor: theme.colors.backgroundSecondary,
            borderTopLeftRadius: theme.borders.radius300,
            borderTopRightRadius: theme.borders.radius300,
            borderBottomRightRadius: theme.borders.radius300,
            borderBottomLeftRadius: theme.borders.radius300,
            ...theme.borders.border200,
        }),
        iconImage: css({
            width: "16px",
            height: "16px",
            filter: isDarkMode ? "invert(1)" : "none",
        }),
        text: css({
            fontSize: "17px",
            fontWeight: 300,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            minWidth: "0",
            textAlign: "left",
        }),
        avatar: css({
            width: "100%",
            height: "100%",
            objectFit: "cover",
            backgroundColor: theme.colors.backgroundAlwaysLight,
        }),
    }
}
