import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Calendar as CalendarOg } from "baseui/datepicker"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { CalendarProps } from "baseui/datepicker"

interface Props extends Omit<CalendarProps, "dateLabel"> {
    readonly renderDateLabel: CalendarProps["dateLabel"]
}

export default function Calendar({ renderDateLabel, ...props }: Props) {
    return <CalendarOg {...props} dateLabel={renderDateLabel} />
}
