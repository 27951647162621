import React, { type JSX } from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useStyletron } from "baseui"
import { useLocation } from "@reach/router"
import { Script } from "gatsby"

import Header from "~components/shared/header/header"
import Footer from "~components/shared/footer"
import Tabbar from "~components/shared/tab-bar/tab-bar"
import useAnalytics from "~utils/hooks/use-analytics"
import { useUser } from "~config/user-provider"

import ImpersonationBox from "./auth/impersonation-box"

// Can't use PropsWithChildren here.
interface Props {
    readonly children: JSX.Element
}

export default function Layout({ children }: Props) {
    const styles = useStyles()
    const {
        shouldShowHeader,
        shouldShowFooter,
        shouldShowTabbar,
        shouldShowImpersonationBox,
    } = useRouteOptions()

    const { user } = useUser()
    useAnalytics(user?.id)

    return (
        <>
            <div className={styles.root}>
                <main className={styles.content}>{children}</main>
                {shouldShowHeader && <Header />}
                {shouldShowFooter && <Footer />}
                {shouldShowTabbar && <Tabbar />}
                {shouldShowImpersonationBox && <ImpersonationBox />}
            </div>
            {/* See docs https://studio.appcues.com/settings/installation/guide/overview */}
            <Script type="text/javascript">
                {`window.AppcuesSettings = { enableURLDetection: true };`}
            </Script>
            <Script src="//fast.appcues.com/209636.js" />
        </>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()
    const { pathname } = useLocation()
    const { shouldShowHeader } = useRouteOptions()

    return {
        root: css({
            backgroundColor: theme.colors.backgroundPrimary,
            color: theme.colors.contentPrimary,
        }),
        content: css({
            position: "relative",
            paddingTop: "0",
            marginTop: "0",
            marginRight: "auto",
            marginBottom: "0",
            marginLeft: "auto",
            minHeight: "100dvh",
            [theme.mediaQuery.medium]: {
                minHeight: "calc(100vh - 72px)",
                paddingTop: pathname === "/" || !shouldShowHeader ? 0 : "72px",
            },
        }),
        loadingScreen: css({
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: theme.colors.contentPrimary,
            backgroundColor: theme.colors.backgroundPrimary,
        }),
    }
}

function useRouteOptions() {
    const { pathname } = useLocation()
    const isWidgetRoute = pathname.startsWith("/widget")

    // This could be more robust, but for now it works well
    if (isWidgetRoute) {
        return {
            shouldShowHeader: false,
            shouldShowFooter: false,
            shouldShowTabbar: false,
            shouldShowImpersonationBox: false,
        }
    }

    return {
        shouldShowHeader: true,
        shouldShowFooter: true,
        shouldShowTabbar: true,
        shouldShowImpersonationBox: true,
    }
}
