import React from "react"

// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"

interface Props {
    readonly label?: string | null
}

export default function ImageCardPill({ label }: Props) {
    const styles = useStyles()

    if (!label) return null

    return (
        <div className={styles.tagWrapper}>
            <div className={styles.label}>{label}</div>
        </div>
    )
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        tagWrapper: css({
            background: theme.colors.backgroundPrimary,
            opacity: 0.9,
            borderRadius: "12px",
            paddingTop: theme.sizing.scale100,
            paddingBottom: theme.sizing.scale100,
            paddingLeft: theme.sizing.scale400,
            paddingRight: theme.sizing.scale400,
            position: "absolute",
            width: "fit-content",
            top: theme.sizing.scale600,
            left: theme.sizing.scale600,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
            ...theme.borders.border200,
        }),
        label: css({
            fontSize: "12px",
            fontWeight: 600,
            color: theme.colors.contentPrimary,
            [theme.mediaQuery.medium]: {
                fontSize: "14px",
            },
        }),
    }
}
