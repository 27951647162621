import React from "react"

import { navigateToGuidetechPage } from "~utils/navigation-helpers"
import { useMobileMediaQueries } from "~utils/use-media-queries"
import COPY_CONSTANTS from "~config/copy-constants"
import { useUser } from "~config/user-provider"
import { PrimaryButton } from "~components/shared/todo-lib-react-components/button"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"

export default function OutfitterCallToActionButton() {
    const { shouldSeeNewOutfitterCtaInHeader } = useUser()
    const styles = useStyles()

    const { isMobileOnly } = useMobileMediaQueries()

    if (!shouldSeeNewOutfitterCtaInHeader) return null

    const copy = isMobileOnly
        ? COPY_CONSTANTS.OUTFITTERS
        : COPY_CONSTANTS.OUTFITTERS_START_HERE

    return (
        <PrimaryButton style={styles.root} onClick={navigateToGuidetechPage}>
            {copy}
        </PrimaryButton>
    )
}

function useStyles() {
    return componentStyles({
        root: {
            rounded: "full",
            height: "unset",
            paddingY: 2,
        },
    })
}
