import type { PropsWithChildren } from "react"
import React, { createContext, useContext, useEffect, useState } from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { BaseProvider } from "baseui"
import usePrefersColorScheme from "use-prefers-color-scheme"
import { Provider, theme } from "@mallardbay/lib-react-components"
// TODO: Right now we need to use the ChakraProvider along with the Provider
// from lib-react-components, otherwise the toasts don't work. We can address
// this once we're done converting everything as a last step.
// eslint-disable-next-line no-restricted-imports
import { ChakraProvider } from "@chakra-ui/react"

// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { LightTheme, DarkTheme } from "~config/baseui-theme"

enum ColorMode {
    LIGHT = "light",
    DARK = "dark",
    NO_PREFERENCE = "no-preference",
}

interface IThemeContext {
    colorMode: ColorMode
    isDarkMode: boolean
}

const ThemeContext = createContext<IThemeContext>({
    colorMode: ColorMode.LIGHT,
    isDarkMode: false,
})

export function ThemeProvider({ children }: Readonly<PropsWithChildren>) {
    const preferedColorMode = usePrefersColorScheme() as ColorMode
    const isDarkMode = preferedColorMode === ColorMode.DARK

    // Keep theme in state so it rehydrates properly
    const [baseuiTheme, setBaseuiTheme] = useState(LightTheme)

    useEffect(() => {
        const activeBaseuiTheme = isDarkMode ? DarkTheme : LightTheme
        setBaseuiTheme(activeBaseuiTheme)
    }, [isDarkMode])

    const value = {
        colorMode: preferedColorMode,
        theme: preferedColorMode,
        isDarkMode,
    }

    return (
        <Provider theme={theme}>
            <ChakraProvider theme={theme}>
                <BaseProvider theme={baseuiTheme}>
                    <ThemeContext.Provider value={value}>
                        {children}
                    </ThemeContext.Provider>
                </BaseProvider>
            </ChakraProvider>
        </Provider>
    )
}

export const useThemeContext = () => {
    return useContext(ThemeContext)
}
