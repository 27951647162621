// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { createTheme, createDarkTheme, createThemedUseStyletron } from "baseui"

import { breakpoints, mediaQuery } from "~config/theme-responsive"
import { Z_INDICES } from "~config/constants"

const primitives = {
    primaryFontFamily: "Nunito Sans",
}

const overrides = {
    breakpoints,
    mediaQuery,
    colors: {
        textColor: "", // Overriden in light and dark themes below
        backgroundAlwaysGrey: "#1F1F1F",
        headingBackground: "#AC2707ff",
        transparent: "transparent",
        primaryOrange: "#f65e38",
        hoverPrimaryOrange: "#e06d1f",
        warningOrange: "#FAAE5A",
        tooltipCard: "#464655",
        dangerColor: "#f87171",
        // Make the color transparent so we can style with overrides
        modalCloseColor: "transparent",
        brand: {
            50: "#FFE8E1",
            100: "#FFC2B3",
            200: "#FB9B83",
            300: "#F87453",
            400: "#F55E38",
            500: "#F54D23",
            600: "#AC2707",
            700: "#7B1B04",
            800: "#4C0E00",
            900: "#1F0200",
        },
    },
    gradients: {
        redToOrange:
            "linear-gradient(to right, #AC2707ff, #C13809ff, #D64A0Bff, #EA5B0Cff, #FF6C0Eff)",
    },
    boxShadows: {
        small: "0 2px 12px 0 rgba(246,116,57,.4)",
        card: `rgba(0, 0, 0, 0.08) 0px 1px 12px`,
    },
    zIndex: {
        floatingButton: Z_INDICES.FLOATING_BUTTON,
        modal: Z_INDICES.MODAL,
        popover: Z_INDICES.POPOVER,
    },
} as const

type ThemeOverrides = typeof overrides

const lightThemePrimitives = {
    ...primitives,
}

const lightThemeOverrides = {
    ...overrides,
    colors: {
        ...overrides.colors,
        textColor: "black",
    },
}

const darkThemePrimitives = {
    ...primitives,
}

const darkThemeOverrides = {
    ...overrides,
    colors: {
        ...overrides.colors,
        textColor: "white",
    },
}

export const LightTheme = createTheme(lightThemePrimitives, lightThemeOverrides)

export const DarkTheme = createDarkTheme(
    darkThemePrimitives,
    darkThemeOverrides
)

export function useTheme() {
    const themedStyletron = createThemedUseStyletron<
        | (typeof LightTheme & ThemeOverrides)
        | (typeof DarkTheme & ThemeOverrides)
    >()

    const [css, theme] = themedStyletron()

    return { css, theme }
}

export type MediaQueryKey = keyof ReturnType<
    typeof useTheme
>["theme"]["mediaQuery"]
