// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useStyletron } from "baseui"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { ButtonOverrides } from "baseui/button"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Button, KIND } from "baseui/button"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Search } from "baseui/icon"
import React from "react"

import { TEST_IDS } from "~config/test-ids"

interface Props {
    readonly resetValues: () => void
}
export default function Footer({ resetValues }: Props) {
    const styles = useStyles()

    return (
        <div className={styles.footer}>
            <Button
                kind={KIND.tertiary}
                onClick={resetValues}
                overrides={getResetButtonOverrides()}
            >
                Clear all
            </Button>
            <Button
                overrides={getButtonOverrides()}
                startEnhancer={<Search size={18} />}
            >
                Search
            </Button>
        </div>
    )
}

function getResetButtonOverrides() {
    return {
        BaseButton: {
            props: {
                "data-testid": TEST_IDS.MOBILE_RESET_BUTTON,
            },
        },
    }
}

function getButtonOverrides(): ButtonOverrides {
    return {
        BaseButton: {
            style: ({ $theme }) => ({
                backgroundColor: "#f77c2b",
                fontWeight: "600",
                paddingTop: $theme.sizing.scale500,
                paddingBottom: $theme.sizing.scale500,
                paddingLeft: $theme.sizing.scale700,
                paddingRight: $theme.sizing.scale800,
                borderTopLeftRadius: $theme.sizing.scale400,
                borderTopRightRadius: $theme.sizing.scale400,
                borderBottomLeftRadius: $theme.sizing.scale400,
                borderBottomRightRadius: $theme.sizing.scale400,
                ":hover": {
                    backgroundColor: "#D5520B",
                },
            }),
        },
    }
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        footer: css({
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "space-between",
            boxShadow: theme.lighting.shadow400,
            backgroundColor: theme.colors.backgroundPrimary,
            paddingTop: theme.sizing.scale400,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
        }),
    }
}
