import { useMediaQuery } from "usehooks-ts"

import { mediaQueryExpressions } from "~config/theme-responsive"

export function useMobileMediaQueries() {
    const isMobileOrLarger = useMediaQuery(mediaQueryExpressions.mobile)
    const isMobileOnly = useMediaQuery(mediaQueryExpressions.mobileOnly)

    return {
        isMobileOrLarger,
        isMobileOnly,
    }
}

export function useTabletMediaQueries() {
    const isTabletOrLarger = useMediaQuery(mediaQueryExpressions.tablet)
    const isTabletOnly = useMediaQuery(mediaQueryExpressions.tabletOnly)

    return {
        isTabletOrLarger,
        isTabletOnly,
    }
}

export function useDesktopMediaQueries() {
    const isDesktopOrLarger = useMediaQuery(mediaQueryExpressions.desktop)
    const isDesktopOnly = useMediaQuery(mediaQueryExpressions.desktopOnly)

    return {
        isDesktopOrLarger,
        isDesktopOnly,
    }
}
