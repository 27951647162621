import { useThemeContext } from "~config/theme-provider"

export const useColorModeValue = (
    lightValue: string | undefined,
    darkValue: string
) => {
    const { isDarkMode } = useThemeContext()

    if (isDarkMode) return darkValue
    return lightValue
}
