import React from "react"

import HeadingMedium from "~components/shared/todo-lib-react-components/heading-medium"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"

interface Props {
    readonly label: string
    readonly shouldCenter?: boolean
}

export default function PageSectionHeading({
    label,
    shouldCenter = false,
}: Props) {
    const styles = useStyles({ shouldCenter })

    return <HeadingMedium style={styles.root}>{label}</HeadingMedium>
}

function useStyles({ shouldCenter }: { shouldCenter: boolean }) {
    return componentStyles({
        root: {
            textAlign: shouldCenter ? "center" : "left",
        },
    })
}
