import React, { useContext } from "react"

import { UserContext } from "~config/user-provider"
import { useAuth } from "~utils/auth-hooks"
import { TextSmall } from "~components/shared/todo-lib-react-components/text"
import Box from "~components/shared/todo-lib-react-components/box"
import {
    componentStyles,
    useTheme,
} from "~components/shared/todo-lib-react-components/hooks/use-theme"

export default function ImpersonationBox() {
    const styles = useStyles()
    const { authUser } = useAuth()
    const { user, isImpersonating } = useContext(UserContext)

    if (!user || !isImpersonating) return null

    return (
        <Box style={styles.root}>
            <Box style={styles.content}>
                <TextSmall>
                    Logged in as {authUser?.name}&nbsp;
                    <strong>({authUser?.email})</strong>
                </TextSmall>
                <TextSmall>
                    Now viewing as&nbsp;
                    <u>
                        {user.first_name} {user.last_name}
                    </u>
                    &nbsp;
                    <strong>({user.email})</strong>
                </TextSmall>
            </Box>
        </Box>
    )
}

function useStyles() {
    const { shadows } = useTheme()

    return componentStyles({
        root: {
            width: "100%",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bottom: { base: "72px", md: "24px" },
        },
        content: {
            margin: "0 8px",
            color: "white",
            backgroundColor: "rgba(76, 14, 0, 0.55)",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            boxShadow: shadows.cardShadow,
            borderRadius: "48px",
            padding: "8px 24px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            minWidth: { md: "300px" },
        },
    })
}
