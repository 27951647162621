import React, { useState, useEffect, useContext, useMemo } from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Input } from "baseui/input"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Notification, KIND } from "baseui/notification"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { ToastOverrides } from "baseui/toast"

import MbModal from "~components/shared/mb-modal"
import FormControl from "~components/shared/form-control"
import PrimaryGradientButton from "~components/shared/primary-gradient-button"
import { UserContext } from "~config/user-provider"
import { validatePhoneNumber } from "~utils/helpers"
import PhoneNumberInput from "~components/shared/form-inputs/phone-number-input"
import COPY from "~config/copy-constants"

const notificationOverrides: ToastOverrides = {
    Body: {
        style: ({ $theme }) => {
            return {
                width: "auto",
                color: $theme.colors.contentOnColor,
                backgroundColor: $theme.colors.negative,
            }
        },
    },
}

function WelcomeModal() {
    const [isOpen, setIsOpen] = useState(false)
    const { user, updateUser, setUserNewData, isUpdating, updateError } =
        useContext(UserContext)
    const [firstName, setFirstName] = useState<string>(user?.first_name || "")
    const [lastName, setLastName] = useState<string>(user?.last_name || "")
    const [phoneNumber, setPhoneNumber] = useState<string>(user?.phone || "")

    useEffect(() => {
        if (user) {
            setFirstName(user.first_name || "")
            setLastName(user.last_name || "")
            setPhoneNumber(user.phone || "")
            setIsOpen(!user.first_name || !user.last_name || !user.phone)
        }
    }, [user, setIsOpen])

    useEffect(() => {
        if (isOpen) {
            setUserNewData({
                first_name: firstName,
                last_name: lastName,
                phone: phoneNumber,
            })
        }
    }, [firstName, isOpen, lastName, phoneNumber])

    const isInputValid = useMemo(
        () =>
            firstName !== "" &&
            lastName !== "" &&
            phoneNumber !== "" &&
            validatePhoneNumber(phoneNumber),
        [firstName, lastName, phoneNumber]
    )

    return (
        <MbModal
            title="Let us get to know you"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            allowClose={false}
        >
            <FormControl label="First name*" caption="">
                <Input
                    id="first-name-input"
                    type="text"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                />
            </FormControl>
            <FormControl label="Last name*" caption="">
                <Input
                    id="last-name-input"
                    type="text"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                />
            </FormControl>
            <PhoneNumberInput
                id="phone-number-input"
                label={COPY.PHONE_LABEL}
                caption={COPY.PHONE_CAPTION}
                value={phoneNumber}
                onChange={setPhoneNumber}
            />
            {updateError && (
                <Notification
                    closeable
                    kind={KIND.negative}
                    overrides={notificationOverrides}
                >
                    Missing or invalid inputs
                </Notification>
            )}
            <PrimaryGradientButton
                fullwidth
                onClick={() => {
                    updateUser({
                        onCompleted: () => setIsOpen(false),
                    })
                }}
                isLoading={isUpdating}
                disabled={!isInputValid}
            >
                Save and continue exploring
            </PrimaryGradientButton>
        </MbModal>
    )
}

export default WelcomeModal
