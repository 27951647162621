import type {
    AllOutfittersFieldsFragment,
    SearchListingFieldsFragment,
    SearchSuggestion,
} from "~graphql/generated/graphql"
import { useDebounceString } from "~utils/use-debounce-string"
import useSearchSuggestions from "~utils/use-search-suggestions"
import useSearchOutfitters from "~utils/use-search-outfitters"
import useSearchListings from "~utils/use-search-listings"
import { getListingRoute, getOutfitterRoute } from "~utils/navigation-helpers"
import COPY from "~config/copy-constants"

const DEBOUNCE_TIME_MS = 300

export default function useSearchBarSuggestions({
    keyword,
}: {
    keyword: string
}) {
    const [debouncedKeyword] = useDebounceString(keyword, DEBOUNCE_TIME_MS)

    const {
        searchSuggestionGroups,
        isLoadingSuggestions,
        isLoadingOutfitters,
        isLoadingListings,
    } = useSearch({ keyword, debouncedKeyword })

    const hasNoSuggestions = doesNotHaveSuggestions(searchSuggestionGroups)
    const isLoading =
        isLoadingSuggestions || isLoadingOutfitters || isLoadingListings

    return { searchSuggestionGroups, isLoading, hasNoSuggestions }
}

function useSearch({
    keyword,
    debouncedKeyword,
}: {
    keyword: string
    debouncedKeyword: string
}) {
    const { searchSuggestions, isLoading: isLoadingSuggestions } =
        useSearchSuggestions()
    const { outfitters, isLoading: isLoadingOutfitters } = useSearchOutfitters({
        keyword: debouncedKeyword,
    })
    const { listings, isLoading: isLoadingListings } = useSearchListings({
        keyword: debouncedKeyword,
    })

    const searchSuggestionGroups = mapSearchData({
        searchSuggestions,
        outfitters,
        listings,
        keyword,
    })

    return {
        searchSuggestionGroups,
        isLoadingSuggestions,
        isLoadingOutfitters,
        isLoadingListings,
    }
}

function doesNotHaveSuggestions(
    searchSuggestionGroups: SearchSuggestionGroup[]
) {
    const hasSearchSuggestions = searchSuggestionGroups.some(
        (suggestionsItem) => Boolean(suggestionsItem.items.length)
    )

    return !hasSearchSuggestions
}

export interface SearchSuggestionGroup {
    title: string
    items: SearchSuggestionItem[]
}

export interface SearchSuggestionItem {
    label: string
    value: string
    icon_url?: string | null
    nav_url?: string | null
    avatar?: string
}

function mapSearchData({
    searchSuggestions,
    outfitters,
    listings,
    keyword,
}: {
    searchSuggestions: SearchSuggestion[]
    outfitters: AllOutfittersFieldsFragment[]
    listings: SearchListingFieldsFragment[]
    keyword: string
}) {
    const filteredSearchSuggestions = filterSearchSuggestionsByKeyword({
        searchSuggestions,
        keyword,
    })
    const outfitterSuggestionsGroup = mapOutfittersToSearchSuggestionsGroup({
        outfitters,
    })
    const listingSuggestionsGroup = mapListingsToSearchSuggestionsGroup({
        listings,
    })

    return [
        ...filteredSearchSuggestions,
        outfitterSuggestionsGroup,
        listingSuggestionsGroup,
    ]
}

function filterSearchSuggestionsByKeyword({
    searchSuggestions,
    keyword,
}: {
    searchSuggestions: SearchSuggestion[]
    keyword: string
}) {
    return searchSuggestions.map((searchSuggestion) => {
        const filteredItems = searchSuggestion.items.filter((item) =>
            item.label.toLowerCase().startsWith(keyword.toLowerCase())
        )

        return { ...searchSuggestion, items: filteredItems }
    })
}

function mapOutfittersToSearchSuggestionsGroup({
    outfitters,
}: {
    outfitters: AllOutfittersFieldsFragment[]
}) {
    const suggestionItems = outfitters.map((outfitter) => ({
        label: outfitter.name,
        value: outfitter.name,
        nav_url: getOutfitterRoute(outfitter.slug),
        avatar: outfitter.avatar?.url,
    }))

    return { title: COPY.OUTFITTERS, items: suggestionItems }
}

function mapListingsToSearchSuggestionsGroup({
    listings,
}: {
    listings: SearchListingFieldsFragment[]
}) {
    const suggestionItems = listings.map((listing) => ({
        label: listing.title ?? "",
        value: listing.title ?? "",
        nav_url: getListingRoute(listing.id),
        avatar: listing.outfitter.avatar?.url,
    }))

    return { title: COPY.LISTINGS, items: suggestionItems }
}
