// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useStyletron } from "baseui"
import React from "react"

interface Props {
    readonly title: string
    readonly isActive: boolean
    readonly activeComponent: React.ReactNode
    readonly inactiveComponent: React.ReactNode
}

export default function CardTitle({
    title,
    isActive,
    activeComponent,
    inactiveComponent,
}: Props) {
    const styles = useStyles()

    return (
        <div className={styles.inactiveWrapper}>
            <p className={isActive ? styles.cardTitle : styles.inactiveTitle}>
                {title}
            </p>
            {isActive ? activeComponent : inactiveComponent}
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        cardTitle: css({
            fontSize: "1.3rem",
            fontWeight: "bold",
        }),
        inactiveTitle: css({
            fontSize: "0.9rem",
            fontWeight: "600",
            color: theme.colors.contentTertiary,
        }),
        inactiveWrapper: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: theme.sizing.scale800,
            paddingRight: theme.sizing.scale800,
        }),
    }
}
