// TODO move useTheme to lib-react-components
import { useTheme as useThemeOg } from "@chakra-ui/react"
import type { Theme } from "@chakra-ui/react"
import { useIsDarkMode } from "@mallardbay/lib-react-components"

import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import type { StyleObject } from "~components/shared/todo-lib-react-components/types"
import { useColorModeValue } from "~utils/use-color-mode"

export function useTheme() {
    // For some reason the og useTheme is not typing properly
    const theme = useThemeOg() as Theme
    const isDarkMode = useIsDarkMode()

    const colors = useColors()
    const shadows = useShadows()
    const borders = useBorders()

    return {
        ...theme,
        colors,
        shadows,
        borders,
        isDarkMode,
    }
}

function useShadows() {
    const cardShadow = useColorModeValue(
        "0 4px 16px hsla(0, 0%, 0%, 0.16)",
        "0 4px 16px hsla(0, 0%, 0%, 0.16)"
    )

    return {
        cardShadow,
    }
}

function useBorders() {
    const border200 = {
        borderColor: "hsla(0, 0%, 100%, 0.08)",
        borderStyle: "solid",
        borderWidth: "1px",
    }

    return {
        border200,
    }
}

export function componentStyles<T extends StyleObject>(styles: T) {
    return styles
}
