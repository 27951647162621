import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Button, KIND, SIZE } from "baseui/button"

interface Props {
    readonly onClick: () => void
}

export default function ClearButton({ onClick }: Props) {
    return (
        <Button kind={KIND.tertiary} size={SIZE.compact} onClick={onClick}>
            Clear
        </Button>
    )
}
