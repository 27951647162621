import React from "react"

import HiddenOnMobileContainer from "~components/shared/wrappers/hidden-on-mobile-container"
import HiddenOnDesktopContainer from "~components/shared/wrappers/hidden-on-desktop-container"
import MobileSearchInput from "~components/shared/search-input/mobile-search-input"
import DesktopSearchInput from "~components/shared/search-input/desktop-search-input"

interface Props {
    readonly query?: string
    readonly dates: Date[]
    readonly setDates: (dates: Date[]) => void
}

export default function SearchInput({ query, dates, setDates }: Props) {
    return (
        <>
            <HiddenOnDesktopContainer mediaQuery="small">
                <MobileSearchInput query={query} dates={dates} />
            </HiddenOnDesktopContainer>

            <HiddenOnMobileContainer mediaQuery="small">
                <DesktopSearchInput
                    query={query}
                    dates={dates}
                    setDates={setDates}
                />
            </HiddenOnMobileContainer>
        </>
    )
}
