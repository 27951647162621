import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { PopoverProps, PopoverOverrides } from "baseui/popover"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Popover as PopoverOg } from "baseui/popover"

// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"

export default function Popover(props: PopoverProps) {
    const popoverOverrides = usePopoverOverrides()

    return <PopoverOg overrides={popoverOverrides} {...props} />
}

function usePopoverOverrides(): PopoverOverrides {
    const { theme } = useTheme()

    return {
        Body: { style: { zIndex: theme.zIndex.popover } },
    }
}
