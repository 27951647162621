import React from "react"
import { type StyleProps } from "@mallardbay/lib-react-components"
import { VStack } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

export function VerticalStack({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <VStack sx={{ ...styles.root, ...style }}>{children}</VStack>
}

function useStyles() {
    const root: StyleProps = {
        gap: 4,
        alignItems: "flex-start",
    }

    return { root }
}
