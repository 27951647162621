import React from "react"
import type { IconType } from "react-icons"

// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"
import { TEST_IDS } from "~config/test-ids"

interface Props {
    readonly title: string
    readonly isActive?: boolean
    readonly Icon?: IconType
}

export default function AccountNavigatorItem({ title, Icon, isActive }: Props) {
    const styles = useStyles({ isActive })

    return (
        <div className={styles.root}>
            {title}
            {Icon && (
                <div className={styles.icon}>
                    <Icon />
                </div>
            )}
            {isActive && (
                <div
                    data-testid={TEST_IDS.ACTIVE_INDICATOR}
                    className={styles.indicator}
                />
            )}
        </div>
    )
}

function useStyles({ isActive }: { isActive?: boolean }) {
    const { css, theme } = useTheme()

    return {
        root: css({
            flex: 1,
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: theme.sizing.scale600,
            color: isActive
                ? theme.colors.contentPrimary
                : theme.colors.contentSecondary,
            fontWeight: isActive ? 700 : 400,
            ":hover": {
                backgroundColor: theme.colors.backgroundSecondary,
            },
        }),
        icon: css({
            fontSize: "18px",
            marginRight: theme.sizing.scale400,
            color: theme.colors.contentPrimary,
        }),
        indicator: css({
            width: "6px",
            height: "100%",
            background: theme.colors.primaryOrange,
        }),
    }
}
