import { useColors as useColorsOg } from "@mallardbay/lib-react-components"

import { useColorModeValue } from "~utils/use-color-mode"

export function useColors() {
    const colors = useColorsOg()

    const cardActiveBoxShadow = "0 4px 16px hsla(0, 0%, 0%, 0.16)"
    const cardBackgroundColor = useColorModeValue("#F6F6F6", "#1F1F1F")
    const text = useColorModeValue(colors.black, colors.white)
    const tag = {
        positive: "green",
        negative: "red",
        neutral: "gray",
    }

    const inverseText = useColorModeValue(colors.white, colors.black)
    const buttonBackground = useColorModeValue("#EEEEEE", "#333333")
    const buttonBackgroundSecondary = useColorModeValue(colors.black, "#E2E2E2")
    const smallBoxShadow = useColorModeValue(colors.borderColor, colors.bgColor)
    const backgroundPrimary = useColorModeValue("#FFFFFF", "#161616")
    const backgroundSecondary = useColorModeValue("#F3F3F3", "#292929")
    const contentTertiary = useColorModeValue("#5E5E5E", "#ABABAB")
    const borderOpaque = useColorModeValue("#E2E2E2", "#333333")
    const accordionBorder = useColorModeValue("#E2E2E2", "#E2E2E2")
    const contentPositive = useColorModeValue("#0E8345", "#5C9D70")
    const warningOrange = "#f65e38"
    const inputBackgroundColor = useColorModeValue("#eeeeee", "#292929")
    const secondaryText = useColorModeValue("#545454", "#CBCBCB")
    const contentPrimary = useColorModeValue("#000000", "#DEDEDE")
    const borderInverseOpaque = useColorModeValue("#333333", "#AFAFAF")
    const brand = "#ff6c0d"
    const colorSchemes = {
        green: "green",
    }

    return {
        ...colors,
        colorSchemes: {
            ...colors.colorSchemes,
            ...colorSchemes,
        },
        cardActiveBoxShadow,
        cardBackgroundColor,
        text,
        inverseText,
        buttonBackground,
        buttonBackgroundSecondary,
        backgroundPrimary,
        backgroundSecondary,
        contentTertiary,
        smallBoxShadow,
        tag,
        borderOpaque,
        accordionBorder,
        contentPositive,
        warningOrange,
        inputBackgroundColor,
        secondaryText,
        brand,
        contentPrimary,
        borderInverseOpaque,
    }
}
