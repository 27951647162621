import {
    OutfitterStatus,
    useSearchOutfittersQuery,
} from "~graphql/generated/graphql"

export default function useSearchOutfitters({
    keyword,
    size = 5,
    page = 0,
}: {
    keyword: string
    size?: number
    page?: number
}) {
    const {
        data,
        loading: isLoading,
        error,
    } = useSearchOutfittersQuery({
        variables: {
            text: keyword,
            status: OutfitterStatus.Approved,
            size,
            page,
        },
    })

    const outfitters = data?.outfitters.results ?? []
    const pages = data?.outfitters.pages ?? 0
    const total = data?.outfitters.total ?? 0

    return { outfitters, pages, total, isLoading, error }
}
