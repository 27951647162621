import React from "react"

import type {
    SearchSuggestionGroup,
    SearchSuggestionItem,
} from "~utils/use-search-bar-suggestions"
import SearchDropdownGroupLabel from "~components/shared/search-input/search-dropdown-group-label"
import SearchDropdownSuggestion from "~components/shared/search-input/search-dropdown-suggestion"

const DEFAULT_ITEMS_TO_RENDER_COUNT = 5

interface Props {
    readonly suggestionGroup: SearchSuggestionGroup
    readonly itemsToRenderCount?: number
    readonly onSuggestionClick: (suggestion: SearchSuggestionItem) => void
}

export default function SearchDropdownSuggestionsGroup({
    suggestionGroup,
    itemsToRenderCount = DEFAULT_ITEMS_TO_RENDER_COUNT,
    onSuggestionClick,
}: Props) {
    if (!suggestionGroup.items.length) return null

    const itemsToShow = suggestionGroup.items.slice(0, itemsToRenderCount)

    return (
        <div>
            <SearchDropdownGroupLabel label={suggestionGroup.title} />
            {itemsToShow.map((item) => (
                <SearchDropdownSuggestion
                    key={item.value}
                    suggestion={item}
                    onClick={onSuggestionClick}
                />
            ))}
        </div>
    )
}
