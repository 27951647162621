import React from "react"
import { FaSearch } from "react-icons/fa"

import { TEST_IDS } from "~config/test-ids"
import COPY from "~config/copy-constants"
import { Button } from "~components/shared/todo-lib-react-components/button"
import { TextSmall } from "~components/shared/todo-lib-react-components/text"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import Box from "~components/shared/todo-lib-react-components/box"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"

interface Props {
    readonly handleClick: () => void
}

export default function HeaderSearchButton({ handleClick }: Props) {
    const styles = useNewStyles()
    const colors = useColors()

    return (
        <Button
            testId={TEST_IDS.HEADER_SEARCH_BUTTON}
            style={styles.root}
            variant="outline"
            onClick={handleClick}
        >
            <TextSmall>{COPY.START_YOUR_ADVENTURE}</TextSmall>
            <Box style={styles.icon}>
                <FaSearch color={colors.inverseText} />
            </Box>
        </Button>
    )
}

function useNewStyles() {
    return componentStyles({
        root: {
            rounded: "full",
            height: "unset",
            paddingY: 1.5,
            paddingRight: 1.5,
            paddingLeft: 5,
            width: "294px",
            justifyContent: "space-between",
        },
        icon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            height: "36px",
            width: "36px",
            backgroundSize: "200% auto",
            backgroundPosition: "inherit",
            backgroundImage:
                "linear-gradient(90deg, rgba(246,94,56,1) 0%, rgba(246,138,57,1) 50%, rgba(246,94,56,1) 100%)",
        },
    })
}
