import React from "react"
import { type StyleProps } from "@mallardbay/lib-react-components"
import { Text as TextOg } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"

export function TextExtraSmall({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <TextOg sx={{ ...styles.xsmall, ...style }}>{children}</TextOg>
}

export function TextSmall({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <TextOg sx={{ ...styles.small, ...style }}>{children}</TextOg>
}

export function Text({ style, children }: PropsWithChildrenAndStyle) {
    return <TextOg sx={style}>{children}</TextOg>
}

export function TextLarge({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <TextOg sx={{ ...styles.large, ...style }}>{children}</TextOg>
}

export function TextError({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <TextOg sx={{ ...styles.error, ...style }}>{children}</TextOg>
}

export function TextBold({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <TextOg sx={{ ...styles.bold, ...style }}>{children}</TextOg>
}

export function TextLargeBold({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <TextBold style={{ ...styles.large, ...style }}>{children}</TextBold>
}

export function TextActive({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <Text style={{ ...styles.active, ...style }}>{children}</Text>
}

export function TextSecondary({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <Text style={{ ...styles.secondary, ...style }}>{children}</Text>
}

export function TextSmallSecondary({
    style,
    children,
}: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return (
        <Text style={{ ...styles.smallSecondary, ...style }}>{children}</Text>
    )
}

export function TextSmallUnderlined({
    style,
    children,
}: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return (
        <Text style={{ ...styles.smallUnderlined, ...style }}>{children}</Text>
    )
}

export function TextNote({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <Text style={{ ...styles.note, ...style }}>{children}</Text>
}

export function TextSmallNote({ style, children }: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return <Text style={{ ...styles.smallNote, ...style }}>{children}</Text>
}

function useStyles() {
    const colors = useColors()

    const error: StyleProps = {
        color: colors.errorColor,
    }
    const xsmall: StyleProps = {
        fontSize: "12px",
    }
    const small: StyleProps = {
        fontSize: "14px",
    }
    const large: StyleProps = {
        fontSize: "18px",
    }
    const bold: StyleProps = {
        fontWeight: "bold",
    }
    const active: StyleProps = {
        fontWeight: "bold",
        color: colors.primaryButtonColor,
    }
    const secondary: StyleProps = {
        color: colors.secondaryText,
    }
    const smallSecondary: StyleProps = {
        ...small,
        ...secondary,
    }
    const smallUnderlined: StyleProps = {
        ...small,
        textDecoration: "underline",
    }
    const note: StyleProps = {
        ...secondary,
        fontStyle: "italic",
    }
    const smallNote: StyleProps = {
        ...smallSecondary,
        fontStyle: "italic",
    }

    return {
        error,
        xsmall,
        small,
        large,
        bold,
        active,
        secondary,
        smallSecondary,
        smallUnderlined,
        note,
        smallNote,
    }
}
