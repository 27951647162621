import { isArray } from "@apollo/client/utilities"

const SMALL = 480
const MEDIUM = 768
const LARGE = 1024
const XLARGE = 1200
const XXLARGE = 1366

export const breakpoints = {
    small: SMALL,
    mobile: SMALL, // Alias for small
    mobileOnly: [0, SMALL], // Alias for small

    medium: MEDIUM,
    tablet: MEDIUM, // Alias for medium
    tabletOnly: [SMALL + 1, MEDIUM],

    large: LARGE,
    desktop: LARGE, // Alias for large
    desktopOnly: [MEDIUM + 1, LARGE],

    xlarge: XLARGE,
    xxlarge: XXLARGE,
} as const

type BreakPoints = typeof breakpoints

type MediaQueryExpression = {
    [Key in keyof BreakPoints]: string
}

type MediaQuery = {
    [Key in keyof BreakPoints]: string
}

export const mediaQueryExpressions = Object.entries(breakpoints).reduce(
    (acc, [key, value]) => ({
        ...acc,
        [key]: getMediaQueryExpressionFromValue(value),
    }),
    {}
) as MediaQueryExpression

export const mediaQuery = Object.entries(mediaQueryExpressions).reduce(
    (acc, [key, expression]) => {
        return {
            ...acc,
            [key]: `@media ${expression}`,
        }
    },
    {}
) as MediaQuery

function getMediaQueryExpressionFromValue(
    value: number | readonly [number, number]
) {
    const prefix = "screen and"
    if (!isArray(value)) return `${prefix} (min-width: ${value}px)`

    // If value[0] is 0, only use max-width
    if (!value[0]) {
        return `${prefix} (max-width: ${value[1]}px)`
    }

    return `${prefix} (min-width: ${value[0]}px) and (max-width: ${value[1]}px)`
}
