import type { ReactNode } from "react"
import React from "react"
import {
    Popover as PopoverOg,
    PopoverTrigger,
    PopoverContent,
    Button,
} from "@chakra-ui/react"

import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"

interface Props extends PropsWithChildrenAndStyle {
    readonly trigger: ReactNode
}

export default function Popover({ style, trigger, children }: Props) {
    const styles = useStyles()

    return (
        <PopoverOg placement="bottom-start" defaultIsOpen={false}>
            <PopoverTrigger>
                <Button variant="unstyled">{trigger}</Button>
            </PopoverTrigger>
            <PopoverContent sx={{ ...styles.content, ...style }}>
                {children}
            </PopoverContent>
        </PopoverOg>
    )
}

function useStyles() {
    const colors = useColors()

    return componentStyles({
        content: {
            backgroundColor: colors.bgColor,
            paddingY: 2,
        },
    })
}
