import { captureException } from "@sentry/react"
import { format } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"

const DEFAULT_TIMEZONE = "America/New_York"
const DATE_FORMAT_DEFAULT = "LLL do, yyyy"
export const DATE_FORMAT_SLASH = "MM/dd/yyyy"
export const FULL_MONTH_DATE_FORMAT = "MMMM d, yyyy"
const DEFAULT_TIME_FORMAT_WITH_TIMEZONE = "h:mm a (zzz)"
const DEFAULT_SHORT_DATE_FORMAT = "MMM d, yyyy"
export const DEFAULT_SHORT_DATE_NO_YEAR_FORMAT = "MMM d"
const DEFAULT_CHECK_IN_DATE_FORMAT = DEFAULT_SHORT_DATE_FORMAT
const DEFAULT_CHECK_IN_TIME_FORMAT = `'at' ${DEFAULT_TIME_FORMAT_WITH_TIMEZONE}`
const DEFAULT_CHECK_IN_FORMAT = `${DEFAULT_CHECK_IN_DATE_FORMAT} ${DEFAULT_CHECK_IN_TIME_FORMAT}`

export function getUserReadableDate(date: Date | number, timeZone?: string) {
    if (timeZone) {
        formatInTimeZone(date, timeZone, DATE_FORMAT_DEFAULT)
    }
    return format(date, DATE_FORMAT_DEFAULT)
}

export function getFullFormattedDateTime(date: Date | number | string) {
    return formatInTimeZone(date, getLocalTimeZone(), DEFAULT_CHECK_IN_FORMAT)
}

export function getLocalTimeZone() {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (error) {
        captureException(error)
        return DEFAULT_TIMEZONE
    }
}
