import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type {
    StatefulContentRenderProp,
    StatefulPopoverProps,
} from "baseui/popover"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { StatefulPopover as StatefulPopoverOg } from "baseui/popover"

interface Props extends Omit<StatefulPopoverProps, "content"> {
    readonly renderContent: StatefulContentRenderProp
}

export default function StatefulPopover({ renderContent, ...props }: Props) {
    return <StatefulPopoverOg {...props} content={renderContent} />
}
