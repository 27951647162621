import React from "react"
import type { WrapPageElementNodeArgs } from "gatsby"

import Layout from "~components/shared/layout"
import { ThemeProvider } from "~config/theme-provider"

export default function WrapPageElement({
    element,
    props,
}: WrapPageElementNodeArgs) {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return (
        <ThemeProvider>
            <Layout {...props}>{element}</Layout>
        </ThemeProvider>
    )
}
