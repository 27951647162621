import React, { type PropsWithChildren } from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Modal, SIZE as ModalSize } from "baseui/modal"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Button, SIZE as ButtonSize, KIND, SHAPE } from "baseui/button"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Delete } from "baseui/icon"

import ConditionalRenderContainer from "~components/shared/conditional-render-container"
// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"

interface MbModalProps extends PropsWithChildren {
    readonly title: string
    readonly isOpen: boolean
    readonly onClose: () => void
    readonly containerStyle?: React.CSSProperties
    readonly allowClose?: boolean
    readonly noPadding?: boolean
    readonly hideHeader?: boolean
    readonly maxWidth?: string
}

export default function MbModal({
    title,
    isOpen,
    onClose,
    containerStyle,
    allowClose = true,
    children,
    noPadding = false,
    hideHeader = false,
    maxWidth,
}: MbModalProps) {
    const styles = useStyles({ maxWidth })
    const modalOverrides = useModalOverrides()

    return (
        <Modal
            size={ModalSize.auto}
            isOpen={isOpen}
            onClose={onClose}
            overrides={modalOverrides}
            closeable={allowClose}
        >
            <ConditionalRenderContainer
                className={styles.header}
                shouldRender={!hideHeader}
            >
                {title}
                {allowClose && (
                    <Button
                        size={ButtonSize.mini}
                        kind={KIND.tertiary}
                        shape={SHAPE.round}
                        className={styles.closeButton}
                        onClick={onClose}
                    >
                        <Delete size={25} />
                    </Button>
                )}
            </ConditionalRenderContainer>
            <div
                className={!noPadding ? styles.content : undefined}
                style={containerStyle}
            >
                {children}
            </div>
        </Modal>
    )
}

function useModalOverrides() {
    const { theme } = useTheme()

    return {
        Root: { style: { zIndex: theme.zIndex.modal } },
        Close: { style: { display: "none" } },
        Dialog: {
            style: {
                borderTopLeftRadius: theme.borders.radius400,
                borderTopRightRadius: theme.borders.radius400,
                borderBottomRightRadius: theme.borders.radius400,
                borderBottomLeftRadius: theme.borders.radius400,
                maxWidth: "90vw",
                overflow: "hidden",
                [theme.mediaQuery.small]: {
                    width: "auto",
                    minWidth: "396px",
                },
            },
        },
    }
}

function useStyles({ maxWidth }: { maxWidth?: string }) {
    const { css, theme } = useTheme()

    return {
        header: css({
            position: "relative",
            paddingTop: theme.sizing.scale700,
            paddingRight: 0,
            paddingBottom: theme.sizing.scale700,
            paddingLeft: 0,
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "center",
            color: theme.colors.contentPrimary,
            borderBottom: `1px solid ${theme.colors.borderOpaque}`,
        }),
        content: css({
            boxSizing: "border-box",
            padding: "24px",
            color: theme.colors.contentPrimary,
            maxWidth,
            [theme.mediaQuery.small]: {
                minWidth: "400px",
                width: "auto",
            },
        }),
        noPaddingContent: css({
            boxSizing: "border-box",
            color: theme.colors.contentPrimary,
            [theme.mediaQuery.small]: {
                minWidth: "400px",
                width: "auto",
            },
        }),
        closeButton: css({
            position: "absolute",
            top: "50%",
            right: "8px",
            transform: "translate(0, -50%)",
        }),
    }
}
