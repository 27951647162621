import React, { useEffect, useState } from "react"
import { Link } from "gatsby-link"

import TextLogoDark from "~images/logos/mb-logo-dark.svg"
import TextLogoLight from "~images/logos/mb-logo.svg"
import COPY from "~config/copy-constants"
import { useThemeContext } from "~config/theme-provider"
import Box from "~components/shared/todo-lib-react-components/box"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"

interface Props {
    readonly isCentered?: boolean
}

export default function TextLogo({ isCentered }: Props) {
    const { isDarkMode } = useThemeContext()
    const styles = useStyles({ isCentered })
    const [logoSrc, setLogoSrc] = useState(TextLogoLight)

    // Wait until client has finished rendering to set the logo src and prevent hydration mismatch
    useEffect(() => {
        setLogoSrc(isDarkMode ? TextLogoDark : TextLogoLight)
    }, [isDarkMode])

    return (
        <Box style={styles.root}>
            <Link to="/">
                <img src={logoSrc} width={200} alt={COPY.MALLARD_BAY_LOGO} />
            </Link>
        </Box>
    )
}

function useStyles({ isCentered }: { isCentered?: boolean }) {
    return componentStyles({
        root: {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            justifyContent: isCentered ? "center" : "flex-start",
        },
    })
}
