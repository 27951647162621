import React, { useState } from "react"
import { useSpring, animated } from "react-spring"

import SearchInput from "~components/shared/search-input/search-input"
import HeaderSearchButton from "~components/shared/header/header-search-button"
import ConditionalRenderContainer from "~components/shared/conditional-render-container"
import { getUrlQueryParam } from "~utils/url-param-helpers"
import { TEST_IDS } from "~config/test-ids"
import Box from "~components/shared/todo-lib-react-components/box"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"

interface SearchBarProps {
    readonly isCollapsed?: boolean
    readonly clickHandler: () => void
}

export default function HeaderSearchBar({
    isCollapsed = false,
    clickHandler,
}: SearchBarProps) {
    const state = getUrlQueryParam("state")
    const species = getUrlQueryParam("species")
    const query = getUrlQueryParam("query")
    const startDate = getUrlQueryParam("startDate")
    const endDate = getUrlQueryParam("endDate")

    const [dates, setDates] = useState<Date[]>(
        getPrefilledDates(startDate, endDate)
    )
    const styles = useStyles()

    const collapsedStyles = useSpring({
        opacity: isCollapsed ? 1 : 0,
        config: { duration: 200 },
    })

    const expandedStyles = useSpring({
        opacity: isCollapsed ? 0 : 1,
        config: { duration: 200 },
    })

    return (
        <>
            <ConditionalRenderContainer shouldRender={isCollapsed}>
                <animated.div style={collapsedStyles}>
                    <HeaderSearchButton handleClick={clickHandler} />
                </animated.div>
            </ConditionalRenderContainer>
            <ConditionalRenderContainer shouldRender={!isCollapsed}>
                <animated.div style={expandedStyles}>
                    <Box
                        testId={TEST_IDS.HEADER_SEARCH_BAR}
                        style={styles.searchContainer}
                    >
                        <Box style={styles.inputWrapper}>
                            <SearchInput
                                query={query || species || state || ""}
                                dates={dates}
                                setDates={setDates}
                            />
                        </Box>
                    </Box>
                </animated.div>
            </ConditionalRenderContainer>
        </>
    )
}

type QueryParamDate = string | (string | null)[] | null

function getPrefilledDates(startDate: QueryParamDate, endDate: QueryParamDate) {
    if (startDate && endDate) {
        return [new Date(Number(startDate)), new Date(Number(endDate))]
    }

    return []
}

function useStyles() {
    return componentStyles({
        searchContainer: {
            width: "100%",
            maxWidth: "750px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        inputWrapper: {
            width: "100%",
            marginTop: { base: "12px", md: 0 },
            marginBottom: { base: "12px", md: 0 },
        },
    })
}
