import { Builder } from "@builder.io/react"

import {
    BuilderioOutfittersCollection,
    BuilderioListingsCollection,
} from "~components/shared/collections/builderio-collections"
import { TYPES as COLLECTION_TYPES } from "~components/shared/collections/generic-collection"
import { LISTING_QUERY_TYPES } from "~components/shared/collections/listings-collection-queryable"
import { OUTFITTER_QUERY_TYPES } from "~components/shared/collections/outfitters-collection-queryable"

import {
    INPUTS,
    INPUT_TYPES,
    DEFAULT_VALUES,
    BUILDER_CUSTOM_COMPONENTS,
} from "./builderio-constants"

// TDO add type checking for input names to match prop names from components used

/**
 * NOTES
 * - Components registered here will be available in the Builder.io editor
 * - Components names should be unique
 * - Avoid changing the name of any component, since it will be removed from any places it's being used in Builder.io
 */
export function registerCustomComponents() {
    Builder.registerComponent(BuilderioOutfittersCollection, {
        name: BUILDER_CUSTOM_COMPONENTS.OUTFITTERS_COLLECTION,
        inputs: [
            ...getCommonCollectionInputs(),
            ...getOutfitterCollectionInputs(),
        ],
    })

    Builder.registerComponent(BuilderioListingsCollection, {
        name: BUILDER_CUSTOM_COMPONENTS.LISTINGS_COLLECTION,
        inputs: [
            ...getCommonCollectionInputs(),
            ...getListingCollectionInputs(),
        ],
    })
}

function getCommonCollectionInputs() {
    return [
        {
            required: true,
            name: INPUTS.COLLECTION_TYPE,
            type: INPUT_TYPES.STRING,
            enum: [COLLECTION_TYPES.SLIDER, COLLECTION_TYPES.GRID],
            defaultValue: COLLECTION_TYPES.SLIDER,
        },
        {
            required: true,
            name: INPUTS.LABEL,
            type: INPUT_TYPES.STRING,
            defaultValue: DEFAULT_VALUES.LABEL,
        },
        {
            name: INPUTS.BUTTON_LABEL,
            type: INPUT_TYPES.STRING,
            defaultValue: DEFAULT_VALUES.BUTTON_LABEL,
        },
        { name: INPUTS.BUTTON_URL, type: INPUT_TYPES.STRING },
    ]
}

function getListingCollectionInputs() {
    return [
        {
            required: true,
            name: INPUTS.QUERY_TYPE,
            type: INPUT_TYPES.STRING,
            enum: Object.values(LISTING_QUERY_TYPES),
            defaultValue: LISTING_QUERY_TYPES.ID_LIST,
        },
        {
            ...getIdMapsInput(),
            showIf: getShowIfExpressionForQueryType(
                LISTING_QUERY_TYPES.ID_LIST
            ),
        },
        {
            name: INPUTS.STATE,
            type: INPUT_TYPES.STRING,
            defaultValue: DEFAULT_VALUES.STATE,
            showIf: getShowIfExpressionForQueryType(LISTING_QUERY_TYPES.STATE),
        },
        {
            name: INPUTS.SEARCH_STRING,
            type: INPUT_TYPES.STRING,
            defaultValue: DEFAULT_VALUES.SEARCH_STRING,
            showIf: getShowIfExpressionForQueryType(LISTING_QUERY_TYPES.SEARCH),
        },
    ]
}

function getOutfitterCollectionInputs() {
    return [
        {
            required: true,
            name: INPUTS.QUERY_TYPE,
            type: INPUT_TYPES.STRING,
            enum: Object.values(OUTFITTER_QUERY_TYPES),
            defaultValue: OUTFITTER_QUERY_TYPES.ID_LIST,
        },
        {
            ...getIdMapsInput(),
            showIf: getShowIfExpressionForQueryType(
                OUTFITTER_QUERY_TYPES.ID_LIST
            ),
        },
    ]
}

function getIdMapsInput() {
    return {
        name: INPUTS.ID_MAPS,
        type: INPUT_TYPES.LIST,
        defaultValue: [],
        subFields: [
            {
                name: INPUTS.ID,
                type: INPUT_TYPES.STRING,
            },
        ],
    }
}

function getShowIfExpressionForQueryType(conditionValue: string) {
    return `options.get('${INPUTS.QUERY_TYPE}') === '${conditionValue}'`
}
