import React from "react"
import {
    Skeleton as SkeletonOg,
    SkeletonCircle as SkeletonCircleOg,
} from "@chakra-ui/react"

import { getArrayOfCount } from "~utils/helpers"
import { TEST_IDS } from "~config/test-ids"

interface Props {
    readonly rows?: number
    readonly height?: number | string
    readonly width?: number | string
}

const DEFAUT_HEIGHT = "1rem"

export default function Skeleton({ height, width, rows = 1 }: Props) {
    const heightToUse = height ?? DEFAUT_HEIGHT
    const widthToUse = width ?? undefined
    const arr = getArrayOfCount(rows)

    return (
        // The empty fragment is needed to prevent a ts error
        <>
            {arr.map((_, index) => (
                <SkeletonOg
                    data-testId={TEST_IDS.LOADING_SKELETON}
                    key={index}
                    height={heightToUse}
                    width={widthToUse}
                />
            ))}
        </>
    )
}

export function SkeletonFill() {
    return <Skeleton height="100%" width="100%" />
}

export function SkeletonCircle(props: Props) {
    return <SkeletonCircleOg {...props} />
}
