import type { ButtonHTMLAttributes } from "react"
import React from "react"
import { Button } from "@chakra-ui/react"
import { type StyleProps } from "@mallardbay/lib-react-components"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

interface Props
    extends PropsWithChildrenAndStyle,
        Omit<ButtonHTMLAttributes<HTMLButtonElement>, "style"> {}

export default function UnstyledButton({ style, ...props }: Props) {
    const styles = useStyles()
    const combinedButtonStyles = { ...styles.button, ...style }

    return <Button {...props} sx={combinedButtonStyles} />
}

function useStyles() {
    const button: StyleProps = {
        textAlign: "inherit",
        background: "none",
        color: "inherit",
        font: "inherit",
        outline: "none",
        border: "none",
        cursor: "pointer",
        padding: 0,
        ":disabled": {
            cursor: "default",
        },
    }

    return {
        button,
    }
}
