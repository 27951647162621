import React, { useContext } from "react"

import { SkeletonCircle } from "~components/shared/todo-lib-react-components/skeleton"
import { UserContext } from "~config/user-provider"
// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"
import UnreadMessageCountBadge from "~components/shared/courier-inbox/unread-message-count-badge"
import type { UserFieldsFragment } from "~graphql/generated/graphql"

export default function Avatar() {
    const { user, isFetching } = useContext(UserContext)
    const styles = useStyles(user)

    if (isFetching) return <SkeletonCircle />

    return (
        <UnreadMessageCountBadge>
            <div className={styles.avatar} />
        </UnreadMessageCountBadge>
    )
}

function useStyles(user?: UserFieldsFragment) {
    const { css, theme } = useTheme()

    return {
        avatar: css({
            height: "25px",
            width: "25px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            marginBottom: "6px",
            boxShadow: theme.lighting.shadow400,
            backgroundImage: `url(${user ? user.avatar_url : ""})`,
        }),
    }
}
