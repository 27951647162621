import { useEffect, type RefObject } from "react"
import { useIntersectionObserver } from "usehooks-ts"

export default function useIsVisible(
    canaryRef: RefObject<HTMLDivElement>,
    freezeOnceVisible = true
) {
    const entry = useIntersectionObserver(canaryRef, {
        freezeOnceVisible,
    })
    return Boolean(entry?.isIntersecting)
}

export function useCallBackOnVisible({
    callback,
    canaryRef,
}: {
    callback: () => void
    canaryRef: RefObject<HTMLDivElement>
}) {
    const isVisible = useIsVisible(canaryRef, false)

    useEffect(() => {
        if (isVisible) {
            callback()
        }
    }, [isVisible])

    return isVisible
}
