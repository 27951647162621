import React from "react"

import COPY_CONSTANTS from "~config/copy-constants"
// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"
import UnreadMessageCountBadge from "~components/shared/courier-inbox/unread-message-count-badge"
import AccountNavigatorItem from "~components/pages/account/account-navigator-item"

export default function MobileAccountNavigatorCourierButton() {
    const styles = useStyles()

    return (
        <div className={styles.root}>
            <AccountNavigatorItem
                title={COPY_CONSTANTS.NOTIFICATIONS}
                Icon={UnreadMessageCountBadge}
            />
        </div>
    )
}

function useStyles() {
    const { theme, css } = useTheme()

    return {
        root: css({
            borderBottom: `1px solid ${theme.colors.borderOpaque} !important`,
        }),
    }
}
