import React from "react"
import { navigate } from "gatsby-link"

// TODO: remove usage of baseui theme
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "~config/baseui-theme"
import useSearchBarSuggestions from "~utils/use-search-bar-suggestions"
import SearchDropdownSuggestionsGroup from "~components/shared/search-input/search-dropdown-suggestions-group"
import ConditionalRenderContainer from "~components/shared/conditional-render-container"
import COPY from "~config/copy-constants"
import LoadingDots from "~components/shared/loading-dots"

interface Props {
    readonly keyword: string
    readonly onKeywordChange: (keyword: string) => void
}

export default function SearchDropdown({ keyword, onKeywordChange }: Props) {
    const styles = useStyles()

    const { searchSuggestionGroups, hasNoSuggestions, isLoading } =
        useSearchBarSuggestions({ keyword })

    return (
        <div className={styles.root}>
            <ConditionalRenderContainer shouldRender={isLoading}>
                <LoadingDots noPadding />
            </ConditionalRenderContainer>

            {searchSuggestionGroups.map((suggestionGroup) => (
                <SearchDropdownSuggestionsGroup
                    key={suggestionGroup.title}
                    suggestionGroup={suggestionGroup}
                    onSuggestionClick={(suggestion) => {
                        if (suggestion.nav_url) {
                            void navigate(suggestion.nav_url)

                            return
                        }

                        onKeywordChange(suggestion.value)
                    }}
                />
            ))}

            <ConditionalRenderContainer
                className={styles.empty}
                shouldRender={hasNoSuggestions && !isLoading}
            >
                {COPY.NO_MATCHING_SUGGESTIONS}
            </ConditionalRenderContainer>
        </div>
    )
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        root: css({
            padding: `${theme.sizing.scale800} 0`,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
        }),
        empty: css({
            textTransform: "uppercase",
            fontSize: "14px",
            fontWeight: 700,
            paddingTop: 0,
            paddingRight: theme.sizing.scale200,
            paddingBottom: 0,
            paddingLeft: theme.sizing.scale200,
            marginTop: theme.sizing.scale200,
            marginRight: "0",
            marginBottom: theme.sizing.scale100,
            marginLeft: "0",
            [theme.mediaQuery.small]: {
                paddingRight: theme.sizing.scale800,
                paddingLeft: theme.sizing.scale800,
            },
        }),
    }
}
