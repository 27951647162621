import type { LegacyRef, UIEventHandler } from "react"
import React, { forwardRef } from "react"
import { Box as BoxOg } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

interface Props extends PropsWithChildrenAndStyle {
    readonly testId?: string
    readonly onClick?: () => void
    readonly onScroll?: UIEventHandler<HTMLDivElement>
}

const Box = forwardRef(
    ({ style, testId, ...props }: Props, ref: LegacyRef<HTMLDivElement>) => {
        return <BoxOg ref={ref} sx={style} data-testid={testId} {...props} />
    }
)

export default Box
