import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { KIND, SHAPE } from "baseui/button"

import CTAButton from "~components/shared/cta-button"
import { useTabletMediaQueries } from "~utils/use-media-queries"

interface Props {
    readonly label?: string | null
    readonly href?: string | null
}

export default function CollectionButton({ label, href }: Props) {
    const { isTabletOrLarger } = useTabletMediaQueries()
    if (!label || !href) return null

    return (
        <CTAButton
            $as="a"
            label={label}
            href={href}
            target="_blank"
            kind={KIND.tertiary}
            shape={SHAPE.pill}
            fullWidth={!isTabletOrLarger}
        />
    )
}
